<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "haoJie",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                            '#切换目标的默认快捷键是"TAB"',
                            '#游戏里视频设置必须用简易配置(就是地上不能有草,有草影响识别尸体)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到江湖豪杰页",
                            "#按图1说明参数:",
                            "1.必填项-技能:打豪杰小怪和BOSS的输出技能,建议用远程",
                            "2.必填项-拾取:可以设置全部拾取和拾取指定物品,确保背包充足",
                            "3.必填项-模式:选择'角色当前位置刷'在角色当前的位置刷豪杰,刷完5次后停止任务." +
                            "选择'坐标x刷到坐标x'角色移动到选填项里设置的坐标,然后刷豪杰,5次后前往下一个坐标继续刷直到停止",
                            "4.选填项-坐标:可以输入6组的坐标,配合上面的模式刷多个地点的豪杰",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#任务栏要显示不被遮挡(图1)",
                            "#豪杰死亡后的尸体根据名字识别拾取(图2图3)",
                            "#把马哨/御风放到快捷栏上,移动时候自动使用",
                            '以上设置好,根据参数设置在指定位置,开始任务',
                        ],
                        img:[
                            "2.png",
                            "3.png",
                            "4.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
                console.log(this.$options.name);
            },100)
        }
    }
</script>

<style scoped>

</style>
